import React, { useMemo, useCallback } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Breadcrumb, Button, Input, Space, Table } from 'antd'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import api from 'api'
import RequestMaster from 'api/types/RequestMaster'
import CommonLayout from 'components/layout/CommonLayout'
import MainContent from 'components/layout/MainContent'
import paths from 'utils/paths'
import useMasterData, { getProcessName } from 'hooks/useMasterData'
import useQueryParams from 'hooks/useQueryParams'
import FilterButton from './FilterButton'
import useCurrentUser from 'hooks/useCurrentUser'
import OperationSelector from './OperationSelector'
import OperationModal, { OperationModalData } from './OperationModal'
import { dateTimeFormat } from 'utils/formatters'
import { PaginationProps } from 'antd/lib/pagination'

const RequestAction = styled.div`
  display: flex;
  justify-content: flex-end;
`
const RequestTable = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`
const FilterContainer = styled.div``
const TableContainer = styled.div`
  width: 100%;
  margin-left: 20px;
  .ant-table-thead th {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
  }
`
const { Column } = Table

type RequestMasterFilter = {
  actNo?: string | null
}

type RequestMasterQuery = {
  keyword?: string
  filter?: RequestMasterFilter
  page?: number
}

type RequestMasterData = {
  items: RequestMaster[]
  meta: {
    currentPage: number
    itemCount: number
    itemsPerPage: number
    totalItems: number
    totalPages: number
  }
}

export default function RequestListPage() {
  const [requestMasterData, setRequestMasterData] = useState<RequestMasterData | undefined>(
    undefined,
  )
  const [isLoading, setLoading] = useState(false)
  const [isOperationModal, setIsOperationModal] = useState(false)
  const [operationModalData, setOperationModalData] = useState<OperationModalData | undefined>(
    undefined,
  )
  const history = useHistory()
  const queryParams = useQueryParams()
  const actNo = queryParams.get('actNo')
  const keyword = queryParams.get('keyword') || undefined
  const page = queryParams.get('page') || undefined

  const filter = useMemo(() => {
    const _filter: RequestMasterFilter = {}
    if (actNo) _filter.actNo = actNo
    return _filter
  }, [actNo])

  const query = useMemo(() => {
    const _query: RequestMasterQuery = {}
    if (Object.keys(filter).length > 0) _query.filter = filter
    if (keyword) _query.keyword = keyword
    if (page) _query.page = +page
    return _query
  }, [filter, keyword, page])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const { data } = await api.get('/request-masters', { ...query })
      data && setRequestMasterData(data)
      setLoading(false)
    }
    fetchData()
  }, [query])

  const masterData = useMasterData()
  const currentUser = useCurrentUser()
  const role = currentUser && currentUser.role
  const operations = masterData ? masterData.operations : []

  const handleOperationSelect = useCallback((value, requestNo, operation) => {
    setIsOperationModal(true)
    setOperationModalData({
      requestNo,
      operation,
    })
  }, [])

  const handleSearch = useCallback(
    (keyword) => {
      queryParams.set('keyword', keyword)
      queryParams.set('page', '1')
      history.push(`?${queryParams.toString()}`)
    },
    [keyword],
  )

  const handlePageChange = useCallback(
    (page) => {
      queryParams.set('page', page.toString())
      history.push(`?${queryParams.toString()}`)
    },
    [page, actNo],
  )

  const requestMasters = requestMasterData?.items || []
  const meta = requestMasterData?.meta
  const pagination: PaginationProps = {
    total: meta && +meta.totalItems,
    current: meta && +meta.currentPage,
    pageSize: meta && +meta.itemsPerPage,
    onChange: handlePageChange,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  }

  return (
    <CommonLayout>
      {operationModalData && (
        <OperationModal
          isOpen={isOperationModal}
          setIsOpen={setIsOperationModal}
          data={operationModalData}
        />
      )}
      <Breadcrumb style={{ margin: '24px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">หน้าแรก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.requestList()}>รายการคำขอ</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <MainContent>
        <RequestAction>
          <Input.Search
            style={{ width: 300 }}
            allowClear
            placeholder="ระบุเลขรับหรือสถานที่"
            enterButton="ค้นหา"
            defaultValue={keyword}
            onSearch={handleSearch}
          />
          {role === 'ossc' && (
            <Button
              type="primary"
              style={{ marginLeft: '20px' }}
              ghost
              icon={<PlusCircleOutlined />}
              onClick={() => history.push(paths.newRequest())}
            >
              สร้างคำขอ
            </Button>
          )}
        </RequestAction>
        <RequestTable>
          <FilterContainer>
            <Space size={20} direction="vertical">
              <FilterButton actNo="01">อาหาร</FilterButton>
              <FilterButton actNo="02">ยา</FilterButton>
              <FilterButton actNo="03">วัตถุเสพติด</FilterButton>
              <FilterButton actNo="04">เครื่องสำอาง</FilterButton>
              <FilterButton actNo="05">วัตถุอันตราย</FilterButton>
              <FilterButton actNo="06">เครื่องมือแพทย์</FilterButton>
              <FilterButton actNo="07">สถานพยาบาล</FilterButton>
              <FilterButton actNo="08">สถานบริการเพื่อสุขภาพ</FilterButton>
            </Space>
          </FilterContainer>
          <TableContainer>
            <Table
              dataSource={requestMasters}
              pagination={pagination}
              bordered
              rowKey="requestNo"
              loading={isLoading}
            >
              <Column
                title="เลขรับ"
                key="requestNo"
                width={30}
                render={({ requestNo }) => (
                  <Link to={paths.requestDetail(requestNo)}>{requestNo}</Link>
                )}
              />
              <Column
                title="เวลา"
                key="createdAt"
                width={100}
                render={({ createdAt }) => <span>{dateTimeFormat(createdAt)}</span>}
              />
              <Column
                title="กระบวนการ"
                key="processNo"
                width={300}
                render={({ processNo }) => getProcessName(masterData, processNo)}
              />
              <Column title="สถานที่" dataIndex="location" key="location" />
              <Column
                title="ขั้นตอนคำสั่ง"
                key="operationState"
                width={100}
                align="center"
                render={({ requestNo, currentOperationNo }) => (
                  <OperationSelector
                    requestNo={requestNo}
                    value={currentOperationNo}
                    operations={operations}
                    onChange={handleOperationSelect}
                  />
                )}
              />
            </Table>
          </TableContainer>
        </RequestTable>
      </MainContent>
    </CommonLayout>
  )
}
