const requestList = () => {
  return '/requests'
}

const newRequest = () => {
  return '/requests/new'
}

const editRequest = (id: string) => {
  return `/requests/${id}/edit`
}

const requestDetail = (id: string) => {
  return `/requests/${id}`
}

const printRequest = (id: string) => {
  return `/requests/${id}/print`
}

export default {
  requestList,
  newRequest,
  editRequest,
  requestDetail,
  printRequest,
}
