import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.extend(buddhistEra)

export function dateFormat(date: Date) {
  return dayjs(date).format('DD/MM/BB')
}

export function timeFormat(date: Date) {
  return dayjs(date).format('HH:mm')
}

export function dateTimeFormat(date: Date) {
  return dayjs(date).format('DD/MM/BB HH:mm')
}
