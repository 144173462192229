import React, { useCallback } from 'react'
import { Modal, Form, Input, DatePicker } from 'antd'
import { SetStateType } from 'types'
import { Operation } from 'api/types/MasterData'
import api from 'api'
import { useHistory } from 'react-router-dom'

export type OperationModalData = {
  requestNo: string | undefined
  operation: Operation | undefined
}

type OperationModalProps = {
  isOpen: boolean
  setIsOpen: SetStateType<boolean>
  data: OperationModalData
}

export default function OperationModal(props: OperationModalProps) {
  const { isOpen, setIsOpen, data } = props
  const { requestNo, operation } = data
  const [form] = Form.useForm()
  const history = useHistory()

  const handleCancel = useCallback(() => setIsOpen(false), [])
  const handleOk = useCallback(() => form.submit(), [])
  const handleSubmit = useCallback(async (values) => {
    await api.patch(`/request-masters/${requestNo}/operation`, values)
    setIsOpen(false)
    history.push('/request-masters')
  }, [])

  if (!operation) return null

  return (
    <Modal
      title={`ขั้นตอนคำสั่ง: ${operation.name}`}
      closable={false}
      maskClosable={false}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={handleSubmit}
        initialValues={{ operationNo: operation.no }}
      >
        <Form.Item name="operationNo" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="เลขที่รับ">
          <span className="ant-form-text">{requestNo}</span>
        </Form.Item>
        {operation.no === '01' && (
          <Form.Item
            rules={[{ required: true, message: 'กรุณาระบุ' }]}
            name="operationEstimateDate"
            label="วันที่คาดว่าจะแล้วเสร็จ"
          >
            <DatePicker placeholder="เลือกวันที่" />
          </Form.Item>
        )}
        {operation.no === '02' && (
          <Form.Item
            rules={[{ required: true, message: 'กรุณาระบุ' }]}
            name="operationOnsiteDate"
            label="วันนัดตรวจสถานที่"
          >
            <DatePicker placeholder="เลือกวันที่" />
          </Form.Item>
        )}
        {operation.no === '04' && (
          <Form.Item
            rules={[{ required: true, message: 'กรุณาระบุ' }]}
            name="operationRemark"
            label="ข้อความ"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
