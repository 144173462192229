import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import Qs from 'qs'
import { logout, useAuth } from 'utils/authProvider'

class Api {
  private instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}/api`,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.paramsSerializer = (params: any) => {
        return Qs.stringify(params, {
          arrayFormat: 'brackets',
          encode: false,
        })
      }
      config.headers = this.prepareRequestHeaders(config.headers)
      return config
    })
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => this.handleSuccess(response).data,
      (error: AxiosError) => this.handleError(error),
    )
  }

  private prepareRequestHeaders(headers: object) {
    const [logged, accessToken] = useAuth()
    if (!logged) return headers
    return { ...headers, Authorization: `Bearer ${accessToken}` }
  }

  private handleSuccess(response: AxiosResponse) {
    return { data: response, error: null }
  }

  onUnauthorized() {
    logout()
    window.location.replace('/login')
  }

  onServerError() {
    alert('Server Error')
  }

  private handleError(error: AxiosError) {
    const { response } = error

    if (response) {
      const { statusCode } = response.data
      if (statusCode === 401) this.onUnauthorized()
      if (statusCode === 500) this.onServerError()
      const _error = { ...error, response }
      return { error: _error.response.data, data: null, raw: _error }
      // return Promise.reject(_error)
    } else {
      return Promise.reject(error)
    }
  }

  get(path: string, params?: object): Promise<{ data: any; error: any }> {
    return this.instance.get(path, { params })
  }

  post(path: string, params: object, options = {}): Promise<{ data: any; error: any }> {
    return this.instance.post(path, params, options)
  }

  put(path: string, params: object, options = {}): Promise<{ data: any; error: any }> {
    return this.instance.put(path, params, options)
  }

  patch(path: string, params: object, options = {}): Promise<{ data: any; error: any }> {
    return this.instance.patch(path, params, options)
  }

  delete(path: string, params: object): Promise<{ data: any; error: any }> {
    return this.instance.delete(path, { params })
  }
}

const api = new Api()

export default api
