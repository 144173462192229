import React from 'react'
import { Select } from 'antd'
import { Operation } from 'api/types/MasterData'

type SelectorProps = {
  requestNo: string
  operations: Operation[]
  onChange: (value: string, requestNo: string, operation: Operation | undefined) => void
  value: string | undefined
}

export default function OperationSelector(props: SelectorProps) {
  const { requestNo, operations, onChange, value } = props
  const options = operations
    // .filter((o) => {
    //   const currentSequence = value ? +value : 0
    //   return +o.no === currentSequence || +o.no === currentSequence + 1
    // })
    .map((o) => ({ label: o.name, value: o.no }))

  return (
    <Select
      placeholder={<span>ตัวเลือก</span>}
      style={{ width: 160, textAlign: 'left' }}
      options={options}
      onChange={(selectedVal) =>
        onChange(
          selectedVal,
          requestNo,
          operations.find((o) => o.no === selectedVal),
        )
      }
      value={value}
    />
  )
}
