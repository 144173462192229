import React, { useCallback } from 'react'
import { Layout, Menu } from 'antd'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

import Logo from './Logo'
import useCurrentUser from 'hooks/useCurrentUser'
import { logout } from 'utils/authProvider'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu

const CustomLayout = styled(Layout)`
  .ant-layout-header {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 24px;
    box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  }
`
const UserMenuContainer = styled.div`
  height: 100%;
  .ant-menu {
    height: 100%;
  }
  .ant-menu-submenu {
    height: 100%;
    border-bottom: none;
    top: 0;
    margin-top: 0 !important;
    &:hover {
      border-bottom: none;
      background-color: #f9f9fc;
    }
  }
`

type LayoutProps = {
  children: React.ReactNode
}

export default function CommonLayout(props: LayoutProps) {
  const currentUser = useCurrentUser()
  const history = useHistory()

  const handleLogout = useCallback(() => {
    logout()
    history.push('/')
  }, [])

  if (!currentUser) return null

  return (
    <CustomLayout>
      <Header>
        <Logo />
        <UserMenuContainer>
          <Menu key="user" mode="horizontal">
            <SubMenu
              title={
                <>
                  {currentUser.avatar}
                  <span style={{ color: '#999', marginLeft: 8 }}>
                    <span>
                      {currentUser.firstName} ({currentUser.roleLabel})
                    </span>
                  </span>
                </>
              }
            >
              <Menu.Item key="logout" onClick={handleLogout}>
                <span>ออกจากระบบ</span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </UserMenuContainer>
      </Header>
      <Content style={{ padding: '0 24px', minHeight: 280 }}>{props.children}</Content>
      <Footer style={{ textAlign: 'center' }}>
        COPYRIGHT © {new Date().getFullYear()} ALL RIGHT RESERVED.
      </Footer>
    </CustomLayout>
  )
}
