import styled from '@emotion/styled'
import { Descriptions, Divider, Skeleton, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import PublicLayout from 'components/layout/PublicLayout'
import api from 'api'
import RequestMaster from 'api/types/RequestMaster'
import { dateFormat, dateTimeFormat, timeFormat } from 'utils/formatters'
import useMasterData, { getActName, getProcessName } from 'hooks/useMasterData'

const { Column } = Table

const Content = styled.div`
  padding: 24px;
  min-height: calc(100vh - 215px);
  background: #fff;
  width: 100%;
`

const PreviewRequestPage = () => {
  const params = useParams<{ id: string }>()
  const [requestMaster, setRequestMaster] = useState<RequestMaster | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const operations = (requestMaster?.operations || [])
    .sort((operation1, operation2) => {
      if (operation1.createdAt > operation2.createdAt) return 1
      if (operation1.createdAt < operation2.createdAt) return -1
      return 0
    })
    .map((operation, index) => ({ ...operation, sequence: index + 1 }))

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const { data } = await api.get(`/request-masters/${params.id}/preview`)
      data && setRequestMaster(data.requestMaster)
      setLoading(false)
    }
    fetchData()
  }, [params.id])

  const masterData = useMasterData()
  const actName = getActName(masterData, requestMaster?.actNo)
  const processName = getProcessName(masterData, requestMaster?.processNo)

  return (
    <PublicLayout>
      <Content>
        {isLoading && <Skeleton active />}
        {requestMaster && (
          <>
            <Descriptions title="รายละเอียดคำขอ" bordered>
              <Descriptions.Item label="เลขที่รับ" span={1}>
                {requestMaster.requestNo}
              </Descriptions.Item>
              <Descriptions.Item label="วันที่รับ" span={1}>
                {dateFormat(requestMaster.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label="เวลา" span={1}>
                {timeFormat(requestMaster.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label="พรบ" span={1}>
                {actName}
              </Descriptions.Item>
              <Descriptions.Item label="กระบวนการ" span={2}>
                {processName}
              </Descriptions.Item>
              <Descriptions.Item label="ชื่อสถานที่" span={2}>
                {requestMaster.location}
              </Descriptions.Item>
              <Descriptions.Item label="อำเภอ" span={1}>
                {requestMaster.district}
              </Descriptions.Item>
              <Descriptions.Item label="ผู้ยื่นคำขอหรือผู้มอบอำนาจ" span={3}>
                {requestMaster.applicant}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="สถานะการดำเนินงาน" />
            <Table dataSource={operations} pagination={false} bordered rowKey="operationNo">
              <Column title="ลำดับ" dataIndex="sequence" key="sequence" />
              <Column
                title="กระบวนการ (ขั้นตอน)"
                key="operationName"
                render={({ operationNo, operationName, operationOnsiteDate, operationRemark }) => {
                  if (operationNo === '02' && operationOnsiteDate !== null) {
                    return `${operationName} : ${dateFormat(operationOnsiteDate)}`
                  } else if (operationNo === '04') {
                    return `${operationName} : ${operationRemark}`
                  } else {
                    return operationName
                  }
                }}
              />
              <Column
                title="วันที่และเวลา"
                key="createdAt"
                render={({ createdAt }) => dateTimeFormat(createdAt)}
              />
              <Column title="ผู้ลงข้อมูล" dataIndex="creatorName" key="creatorName" />
            </Table>
          </>
        )}
      </Content>
    </PublicLayout>
  )
}

export default PreviewRequestPage
