import React, { useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import PublicLayout from 'components/layout/PublicLayout'
import { Form, Input, Space, Statistic } from 'antd'
import useQueryParams from 'hooks/useQueryParams'
import api from 'api'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px;
`

export default function SearchRequestPage() {
  const queryParams = useQueryParams()
  const keyword = queryParams.get('keyword') || undefined
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)
  const [previewsCount, setPreviewsCount] = useState<number>(0)

  useEffect(() => {
    async function fetchPreviewsCount() {
      setIsPreviewLoading(true)
      const { data } = await api.get('/request-masters/previews-count')
      setIsPreviewLoading(false)
      data && setPreviewsCount(data.previewsCount)
    }
    fetchPreviewsCount()
  }, [])

  const handleSearch = useCallback(
    async (requestNo) => {
      setIsPreviewLoading(true)
      const { data, error } = await api.post(`/request-masters/${requestNo}/preview`, {})
      setIsPreviewLoading(false)

      if (data) {
        setPreviewsCount(data.previewsCount)
        window.open(`/requests/${requestNo}/preview`, '_blank')
      } else {
        alert(error.message)
      }
    },
    [keyword],
  )

  return (
    <PublicLayout>
      <Content>
        <Form layout="vertical">
          <Form.Item label="เลขที่รับคำขอ">
            <Input.Search
              style={{ width: 300 }}
              allowClear
              disabled={isPreviewLoading}
              placeholder="ตัวอย่าง: 63010001"
              enterButton="ค้นหา"
              onSearch={handleSearch}
            />
          </Form.Item>
        </Form>
        <Statistic
          title="จํานวนครั้งที่ค้นหา"
          valueStyle={{ opacity: isPreviewLoading ? 0.5 : 1 }}
          value={previewsCount}
        />
      </Content>
    </PublicLayout>
  )
}
