const ACCESS_TOKEN = 'accessToken'

export function useAuth() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)
  const logged = accessToken !== null
  return [logged, accessToken]
}

export function login(accessToken: string) {
  return localStorage.setItem(ACCESS_TOKEN, accessToken)
}

export function logout() {
  return localStorage.removeItem(ACCESS_TOKEN)
}
