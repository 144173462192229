import React, { useCallback } from 'react'

import PublicLayout from 'components/layout/PublicLayout'

import LoginForm from './login-components/LoginForm'
import styled from '@emotion/styled'
import api from 'api'
import { Store } from 'antd/lib/form/interface'
import { login, useAuth } from 'utils/authProvider'
import { Redirect, useHistory } from 'react-router-dom'
import useAppState from 'hooks/useAppState'

const Content = styled.div`
  width: 100%;
`

export default function LoginPage() {
  const [logged] = useAuth()
  const [appState, setAppState] = useAppState()

  const history = useHistory()
  const handleSubmit = useCallback(async (values: Store) => {
    const { data, error } = await api.post('/auth/login', values)
    if (data) {
      login(data.accessToken)
      setAppState({ ...appState, currentUser: data.currentUser })
      history.push('/ossc/requests')
    } else {
      alert('เข้าสู่ระบบไม่สำเร็จ')
      console.log(error)
    }
  }, [])

  if (logged) return <Redirect to="/" />

  return (
    <PublicLayout>
      <Content>
        <LoginForm onSubmit={handleSubmit} />
      </Content>
    </PublicLayout>
  )
}
