import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'antd'
import useQueryParams from 'hooks/useQueryParams'
import { useHistory } from 'react-router-dom'

const CustomButton = styled(Button)`
  width: 170px;
  font-weight: bold;
`

type FilterButtonProps = {
  actNo: string
  children?: React.ReactNode
}

export default function FilterButton(props: FilterButtonProps) {
  const { actNo, children } = props
  const queryParams = useQueryParams()
  const history = useHistory()

  return (
    <CustomButton
      type={queryParams.get('actNo') === actNo ? 'primary' : 'default'}
      onClick={() => {
        queryParams.set('actNo', actNo)
        // history.push(`?${queryParams.toString()}`)
        history.push(`?actNo=${actNo}`)
      }}
    >
      {children}
    </CustomButton>
  )
}
