import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

const LogoContainer = styled.div`
  color: #ff4d4f;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`
interface ILogo {
  shorten?: boolean
}

const Logo: FC<ILogo> = (props) => {
  const history = useHistory()
  const text = props.shorten ? 'OSSC' : 'OSSC Tracking'
  return <LogoContainer onClick={() => history.push('/')}>{text}</LogoContainer>
}

export default Logo
