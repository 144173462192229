import { Breadcrumb } from 'antd'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import CommonLayout from 'components/layout/CommonLayout'
import MainContent from 'components/layout/MainContent'
import paths from 'utils/paths'
import api from 'api'

import RequestForm from '../components/RequestForm'
import { Store } from 'antd/lib/form/interface'

const NewRequestPage = () => {
  const history = useHistory()
  const listPath = paths.requestList()

  const handleSubmit = useCallback(async (values: Store) => {
    const { data } = await api.post('/request-masters', values)
    data && history.push(listPath)
  }, [])

  return (
    <CommonLayout>
      <Breadcrumb style={{ margin: '24px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">หน้าแรก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.requestList()}>รายการคำขอ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.newRequest()}>สร้างคำขอ</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <MainContent>
        <RequestForm onSubmit={handleSubmit} />
      </MainContent>
    </CommonLayout>
  )
}

export default NewRequestPage
