import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React from 'react'
import useAppState from './useAppState'

const roles = {
  ossc: {
    label: 'OSSC',
    value: 'ossc',
    avatar: <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />,
  },
  resp: {
    label: 'ผู้รับผิดชอบ',
    value: 'resp',
    avatar: <Avatar style={{ backgroundColor: '#faad14' }} icon={<UserOutlined />} />,
  },
}

export default function useCurrentUser() {
  const appState = useAppState()[0]
  const currentUser = appState.currentUser

  if (!currentUser) return null

  const role = roles[currentUser.role]
  const enhanchedCurrentUser = { ...currentUser, avatar: role.avatar, roleLabel: role.label }

  return enhanchedCurrentUser
}
