import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import RequestMaster from 'api/types/RequestMaster'
import { dateFormat, timeFormat } from 'utils/formatters'
import Operation from 'api/types/Operation'

Font.register({
  family: 'Sarabun',
  fonts: [
    { src: '/fonts/Sarabun/Sarabun-ExtraLight.ttf', fontWeight: 'extralight' },
    { src: '/fonts/Sarabun/Sarabun-Light.ttf', fontWeight: 'light' },
    { src: '/fonts/Sarabun/Sarabun-Regular.ttf', fontWeight: 'normal' },
    { src: '/fonts/Sarabun/Sarabun-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/Sarabun/Sarabun-BoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
  ],
})

const styles = StyleSheet.create({
  page: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 14,
    fontFamily: 'Sarabun',
    fontWeight: 'extralight',
    fontSize: 14,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  boldText: {
    fontWeight: 'normal',
  },
  underlineText: {
    textDecoration: 'underline',
  },
  headerLogo: {
    width: 433,
  },
  qrCodeView: {
    border: 1,
    borderColor: 'black',
    marginLeft: 15,
    marginTop: 15,
    width: 110,
    height: 110,
  },
  titleSection: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'normal',
  },
  introSection: {
    marginTop: 10,
    paddingLeft: 45,
    paddingRight: 45,
    lineHeight: 2,
  },
  contentSection: {
    marginLeft: 45,
    marginRight: 45,
    marginTop: 10,
    // border: 1,
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentSpace: {
    marginTop: 20,
    width: '100%',
  },
  contentLabel: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  contentValue: {
    borderBottom: 0.7,
    textAlign: 'center',
    fontSize: 12,
    paddingLeft: 5,
    paddingRight: 5,
  },
})

type ContentValueTextProps = {
  width: number | string
  newline?: boolean
  children: React.ReactNode
}
function ContentValueText(props: ContentValueTextProps) {
  const { children, width, newline } = props
  const textAlign = newline ? 'left' : 'center'
  const paddingBottom = newline ? 5 : 0

  return <Text style={{ ...styles.contentValue, width, textAlign, paddingBottom }}>{children}</Text>
}

function ContentSpace() {
  return <View style={styles.contentSpace} />
}

type PdfDocumentProps = {
  requestMaster: RequestMaster
  actName?: string
  processName?: string
}

export default function PdfDocument(props: PdfDocumentProps) {
  const { requestMaster, actName, processName } = props
  const {
    requestNo,
    createdAt,
    location,
    district,
    applicant,
    phoneNumber,
    email,
    remark,
    operationOnsiteDate,
    operationEstimateDate,
  } = requestMaster

  return (
    <Document>
      {[1, 2].map((n) => (
        <Page size="A4" style={styles.page} key={n}>
          <View style={styles.row}>
            <View style={styles.headerLogo}>
              <Image src="/images/printing-header.png" />
            </View>
            <View style={styles.qrCodeView}>
              <Image src="/images/printing-qr-code.png" />
            </View>
          </View>
          <View style={styles.titleSection}>
            <Text>ศูนย์บริการผลิตภัณฑ์สุขภาพเบ็ดเสร็จ (One Stop Service Center)</Text>
          </View>
          <View style={styles.introSection}>
            <Text>สำนักงานสาธารณสุขจังหวัดนครปฐม โทร. 0 3424 3620 ถึง 1 ต่อ 2</Text>
            <Text>
              ให้นำใบนี้มาติดต่อทุกครั้ง ไปรษณีย์อิเล็กทรอนิกส์ :{' '}
              <Text style={[styles.boldText, styles.underlineText]}>fdapv73@fda.moph.go.th</Text>
            </Text>
            <Text>
              เว็บไซต์ : สำนักงานสาธารณสุขจังหวัดนครปฐม{' '}
              <Text style={styles.boldText}>nptho.moph.go.th</Text>
            </Text>
          </View>
          <View style={styles.contentSection}>
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>เลขที่รับ</Text>
              <ContentValueText width={120}>{requestNo}</ContentValueText>
              <Text style={styles.contentLabel}>วันที่รับ</Text>
              <ContentValueText width={120}>{dateFormat(createdAt)}</ContentValueText>
              <Text style={styles.contentLabel}>เวลา</Text>
              <ContentValueText width={110}>{timeFormat(createdAt)}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>พระราชบัญญัติ</Text>
              <ContentValueText width={380}>{actName}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>กระบวนการ</Text>
              <ContentValueText width={401}>{processName}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>ชื่อสถานที่</Text>
              <ContentValueText width={414}>{location}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>อำเภอ</Text>
              <ContentValueText width={438}>{district}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>ผู้รับมอบอำนาจ</Text>
            </View>
            <View style={{ marginTop: 5, width: '100%' }} />
            <View style={styles.contentRow}>
              <ContentValueText width={'100%'} newline>
                {applicant}
              </ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>เบอร์โทร</Text>
              <ContentValueText width={423}>{phoneNumber}</ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>E-mail ผู้รับมอบอำนาจ</Text>
            </View>
            <View style={{ marginTop: 5, width: '100%' }} />
            <View style={styles.contentRow}>
              <ContentValueText width={'100%'} newline>
                {email}
              </ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>วันที่นัดตรวจ</Text>
              <ContentValueText width={397}>
                {operationOnsiteDate ? dateFormat(operationOnsiteDate) : ''}
              </ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>วันที่คาดว่าจะแล้วเสร็จ</Text>
              <ContentValueText width={333}>
                {operationEstimateDate ? dateFormat(operationEstimateDate) : ''}
              </ContentValueText>
            </View>
            <ContentSpace />
            <View style={styles.contentRow}>
              <Text style={styles.contentLabel}>หมายเหตุ</Text>
            </View>
            <View style={{ marginTop: 5, width: '100%' }} />
            <View style={styles.contentRow}>
              <ContentValueText width={'100%'} newline>
                {remark}
              </ContentValueText>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}
