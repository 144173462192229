import React, { createContext } from 'react'

import PagesRouter from 'pages/pages.router'
import { useState } from 'react'
import { useEffect } from 'react'
import api from 'api'
import { useAuth } from 'utils/authProvider'
import { SetStateType } from 'types'
import User from 'api/types/User'

export type AppState = {
  initialized: boolean
  currentUser: User | null
}

const initialAppState: AppState = {
  initialized: false,
  currentUser: null,
}

export const AppStateContext = createContext<[AppState, SetStateType<AppState>]>([
  initialAppState,
  () => {},
])

export default function App() {
  const [appState, setAppState] = useState<AppState>(initialAppState)
  const [logged] = useAuth()

  useEffect(() => {
    async function fetchCurrentUser() {
      const { data } = await api.get('/auth/current-user')
      const currentUser = data ? data.currentUser : null
      setAppState({ ...appState, initialized: true, currentUser })
    }

    if (logged) {
      fetchCurrentUser()
    } else {
      setAppState({ ...appState, initialized: true })
    }
  }, [])

  return (
    <AppStateContext.Provider value={[appState, setAppState]}>
      <PagesRouter />
    </AppStateContext.Provider>
  )
}
