import React, { FC } from 'react'
import { Layout } from 'antd'
import styled from '@emotion/styled'

import Logo from './Logo'

const { Content, Footer } = Layout

const CustomLayout = styled(Layout)`
  .ant-layout-header {
    background-color: #fff;
    height: 72px;
    z-index: 29;
    box-shadow: none;
  }
`
const LogoWrapper = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  padding: 0 24px;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
`
const MainContent = styled.div`
  background: #fff;
  padding: 24px;
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
`
const PublicLayout: FC = (props) => (
  <CustomLayout>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <Content style={{ padding: '0 50px' }}>
      <MainContent>{props.children}</MainContent>
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      COPYRIGHT © {new Date().getFullYear()} ALL RIGHT RESERVED.
    </Footer>
  </CustomLayout>
)

export default PublicLayout
