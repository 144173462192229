import React, { FC } from 'react'
import { Skeleton } from 'antd'
import styled from '@emotion/styled'

const MainContentBody = styled.div`
  padding: 24px;
  min-height: calc(100vh - 215px);
  background: #fff;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
`

type MainContainProps = {
  isLoading?: boolean
}

const MainContent: FC<MainContainProps> = ({ isLoading, children }) => (
  <MainContentBody>{isLoading ? <Skeleton active /> : children}</MainContentBody>
)

export default MainContent
