import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import RequestListPage from 'pages/requests/root'

import EditRequestPage from 'pages/requests/_id/edit'
import RequestDetailPage from 'pages/requests/_id/root'
import NewRequestPage from 'pages/requests/new'
import SearchRequestPage from 'pages/requests/search'
import PreviewRequestPage from 'pages/requests/_id/preview'

import LoginPage from 'pages/login/login.page'
import { useAuth } from 'utils/authProvider'
import React from 'react'

type ProtectedRouteProps = {
  path: string
  children: React.ReactNode
}

function ProtectedRoute({ path, children }: ProtectedRouteProps) {
  const [logged] = useAuth()
  if (!logged) return <Redirect to="/login" />
  return <Route path={path} children={children} />
}

export default function PagesRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/requests/search">
          <SearchRequestPage />
        </Route>
        <Route path="/requests/:id/preview">
          <PreviewRequestPage />
        </Route>
        <ProtectedRoute path="/requests/new">
          <NewRequestPage />
        </ProtectedRoute>
        <ProtectedRoute path="/requests/:id/edit">
          <EditRequestPage />
        </ProtectedRoute>
        <ProtectedRoute path="/requests/:id">
          <RequestDetailPage />
        </ProtectedRoute>
        <ProtectedRoute path="/requests">
          <RequestListPage />
        </ProtectedRoute>
        {/* <ProtectedRoute path="/">
          <LandingPage />
        </ProtectedRoute> */}
        <Redirect to="/requests" />
      </Switch>
    </Router>
  )
}
