import { Breadcrumb } from 'antd'
import React, { FC, useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import CommonLayout from 'components/layout/CommonLayout'
import MainContent from 'components/layout/MainContent'
import paths from 'utils/paths'

import RequestForm from '../../components/RequestForm'
import RequestMaster from 'api/types/RequestMaster'
import api from 'api'
import { Store } from 'antd/lib/form/interface'
import { VoidFunc } from 'types'

const handleSubmit = async (id: string, values: Store, callback: VoidFunc) => {
  await api.put(`/request-masters/${id}`, values)
  callback()
}

const EditRequestPage: FC = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const listPath = paths.requestDetail(params.id)

  const [requestMaster, setRequestMaster] = useState<RequestMaster | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const { data } = await api.get(`/request-masters/${params.id}`)
      data && setRequestMaster(data.requestMaster)
      setLoading(false)
    }
    fetchData()
  }, [params.id])

  return (
    <CommonLayout>
      <Breadcrumb style={{ margin: '24px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">หน้าแรก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.requestList()}>รายการคำขอ</Link>
        </Breadcrumb.Item>
        {requestMaster && (
          <Breadcrumb.Item>
            <Link to={paths.editRequest(requestMaster.requestNo)}>แก้ไขคำขอ</Link>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <MainContent isLoading={isLoading}>
        {requestMaster && (
          <RequestForm
            initialValues={requestMaster}
            onSubmit={(values: Store) =>
              handleSubmit(params.id, values, () => history.push(listPath))
            }
          />
        )}
      </MainContent>
    </CommonLayout>
  )
}

export default EditRequestPage
