import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Button, Form, Input, Select } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'
import { useHistory } from 'react-router-dom'

import useMasterData, { getActOptions, getProcessOptions } from 'hooks/useMasterData'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'

const FormRow = styled.div`
  display: flex;
  .ant-form-item {
    margin-left: 20px;
  }
  .ant-form-item-control,
  input {
    min-width: 150px;
  }
`

const { Option } = Select

export type RequestFormProps = {
  initialValues?: Store
  onSubmit?: (values: Store) => void
}

const RequestForm = (props: RequestFormProps) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const initialValues = props.initialValues || {}
  const isEdit = !!initialValues.requestNo

  const handleChangeActSelect = (value: string) => {
    form.setFieldsValue({ processNo: undefined })
  }

  const masterData = useMasterData()
  if (!masterData) return null

  return (
    <Form
      form={form}
      initialValues={{ ...initialValues }}
      onFinish={props.onSubmit}
      layout="horizontal"
    >
      <FormRow>
        <Form.Item rules={[{ required: true, message: 'กรุณาระบุ' }]} name="actNo" label="พรบ">
          <Select
            style={{ width: 450 }}
            placeholder=""
            onChange={handleChangeActSelect}
            options={getActOptions(masterData)}
            disabled={isEdit}
          />
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.actNo !== currentValues.actNo}
        >
          {({ getFieldValue }) => {
            const actNo = getFieldValue('actNo')
            return (
              <Form.Item
                rules={[{ required: true, message: 'กรุณาระบุ' }]}
                name="processNo"
                label="กระบวนการ"
              >
                <Select
                  style={{ width: 500 }}
                  placeholder=""
                  disabled={!actNo}
                  options={getProcessOptions(masterData, actNo)}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </FormRow>
      <Form.List name="moreProcesses">
        {(fields: FormListFieldData[], { add, remove }: FormListOperation) => (
          <>
            {fields.map((field, index) => (
              <FormRow key={field.key}>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.actNo !== currentValues.actNo
                  }
                  style={{ marginLeft: '112px' }}
                >
                  {({ getFieldValue }) => {
                    const actNo = getFieldValue('actNo')
                    return (
                      <>
                        <Form.Item
                          {...field}
                          name={[field.name, 'processNo']}
                          // fieldKey={[field.fieldKey, 'sight']}
                          rules={[{ required: true, message: 'กรุณาระบุ' }]}
                          noStyle
                        >
                          <Select
                            style={{ width: 500 }}
                            placeholder=""
                            disabled={!actNo}
                            options={getProcessOptions(masterData, actNo)}
                          />
                        </Form.Item>
                        {fields.length > 0 ? (
                          <MinusCircleOutlined
                            className={css`
                              margin: 0 8px;
                              cursor: pointer;
                              position: relative;
                              top: 4px;
                              font-size: 24px;
                              color: #999;
                              transition: all 0.3s;
                              &:hover {
                                color: #777;
                              }
                            `}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </>
                    )
                  }}
                </Form.Item>
              </FormRow>
            ))}
            <FormRow>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  เพิ่มกระบวนการ
                </Button>
              </Form.Item>
            </FormRow>
          </>
        )}
      </Form.List>
      <FormRow>
        <Form.Item
          rules={[{ required: true, message: 'กรุณาระบุ' }]}
          style={{ width: 450 }}
          name="location"
          label="ชื่อสถานที่"
        >
          <Input />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: 'กรุณาระบุ' }]} name="district" label="อำเภอ">
          <Select placeholder="">
            <Option value="เมือง">เมือง</Option>
            <Option value="นครชัยศรี">นครชัยศรี</Option>
            <Option value="สามพราน">สามพราน</Option>
            <Option value="กำแพงแสน">กำแพงแสน</Option>
            <Option value="พุทธมณฑล">พุทธมณฑล</Option>
            <Option value="บางเลน">บางเลน</Option>
            <Option value="ดอนตูม">ดอนตูม</Option>
            <Option value="อื่นๆ">อื่นๆ</Option>
          </Select>
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item
          rules={[{ required: true, message: 'กรุณาระบุ' }]}
          name="applicant"
          label="ชื่อผู้ยื่นคำขอหรือผู้รับมอบอำนาจ"
        >
          <Input />
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item
          rules={[
            { required: true, message: 'กรุณาระบุ' },
            { pattern: /^\d{13}$/, message: 'ต้องเป็นตัวเลข ความยาว 13 ตัวอักษร' },
          ]}
          name="personalId"
          label="เลขบัตรประชาชน"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'กรุณาระบุ' }]}
          name="phoneNumber"
          label="เบอร์โทร"
        >
          <Input />
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item
          rules={[{ pattern: /^[^@\s]+@[^@\s]+$/, message: 'รูปแบบอีเมลไม่ถูกต้อง' }]}
          name="email"
          label="อีเมล"
        >
          <Input style={{ width: 300 }} />
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item name="remark" label="หมายเหตุ">
          <Input style={{ width: 300 }} />
        </Form.Item>
      </FormRow>
      <FormRow>
        <Form.Item rules={[{ required: true, message: 'กรุณาระบุ' }]}>
          <Button type="primary" htmlType="submit">
            ยืนยัน
          </Button>
          <Button type="ghost" style={{ marginLeft: '10px' }} onClick={history.goBack}>
            ยกเลิก
          </Button>
        </Form.Item>
      </FormRow>
    </Form>
  )
}

export default RequestForm
