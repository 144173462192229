import { EditOutlined, PrinterOutlined, SettingOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Breadcrumb, Button, Descriptions, Divider, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import CommonLayout from 'components/layout/CommonLayout'
import MainContent from 'components/layout/MainContent'
import paths from 'utils/paths'
import { BlobProvider } from '@react-pdf/renderer'
import PdfDocument from '../../components/PdfDocument'
import api from 'api'
import RequestMaster from 'api/types/RequestMaster'
import { dateFormat, dateTimeFormat, timeFormat } from 'utils/formatters'
import useMasterData, { getActName, getProcessName } from 'hooks/useMasterData'
import useCurrentUser from 'hooks/useCurrentUser'

const { Column } = Table

const RequestAction = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RequestDetail = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [requestMaster, setRequestMaster] = useState<RequestMaster | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const operations = (requestMaster?.operations || [])
    .sort((operation1, operation2) => {
      if (operation1.createdAt > operation2.createdAt) return 1
      if (operation1.createdAt < operation2.createdAt) return -1
      return 0
    })
    .map((operation, index) => ({ ...operation, sequence: index + 1 }))

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const { data } = await api.get(`/request-masters/${params.id}`)
      data && setRequestMaster(data.requestMaster)
      setLoading(false)
    }
    fetchData()
  }, [params.id])

  const masterData = useMasterData()
  const actName = getActName(masterData, requestMaster?.actNo)
  const processName = getProcessName(masterData, requestMaster?.processNo)
  const currentUser = useCurrentUser()

  return (
    <CommonLayout>
      <Breadcrumb style={{ margin: '24px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">หน้าแรก</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.requestList()}>รายการคำขอ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {requestMaster && (
            <Link to={paths.requestDetail(requestMaster.requestNo)}>{requestMaster.requestNo}</Link>
          )}
        </Breadcrumb.Item>
      </Breadcrumb>
      <MainContent isLoading={isLoading}>
        {requestMaster && (
          <>
            {currentUser && currentUser.role === 'ossc' && (
              <RequestAction>
                <Button
                  type="primary"
                  style={{ marginLeft: '20px' }}
                  ghost
                  icon={<EditOutlined />}
                  onClick={() => history.push(paths.editRequest(requestMaster.requestNo))}
                >
                  แก้ไขคำขอ
                </Button>
                <BlobProvider
                  document={
                    <PdfDocument
                      requestMaster={requestMaster}
                      actName={actName}
                      processName={processName}
                    />
                  }
                >
                  {({ blob, url, loading, error }) => {
                    return !loading && url ? (
                      <Button
                        type="primary"
                        style={{ marginLeft: '20px' }}
                        ghost
                        icon={<PrinterOutlined />}
                        onClick={() => window.open(url, '_blank')}
                      >
                        พิมพ์คำขอ
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        style={{ marginLeft: '20px' }}
                        ghost
                        disabled
                        icon={<SettingOutlined spin />}
                      >
                        พิมพ์คำขอ
                      </Button>
                    )
                  }}
                </BlobProvider>
              </RequestAction>
            )}
            <Descriptions title="รายละเอียดคำขอ" bordered>
              <Descriptions.Item label="เลขที่รับ" span={1}>
                {requestMaster.requestNo}
              </Descriptions.Item>
              <Descriptions.Item label="วันที่รับ" span={1}>
                {dateFormat(requestMaster.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label="เวลา" span={1}>
                {timeFormat(requestMaster.createdAt)}
              </Descriptions.Item>
              <Descriptions.Item label="พรบ" span={1}>
                {actName}
              </Descriptions.Item>
              <Descriptions.Item label="กระบวนการ" span={2}>
                {processName}
              </Descriptions.Item>
              <Descriptions.Item label="ชื่อสถานที่" span={2}>
                {requestMaster.location}
              </Descriptions.Item>
              <Descriptions.Item label="อำเภอ" span={1}>
                {requestMaster.district}
              </Descriptions.Item>
              <Descriptions.Item label="ผู้ยื่นคำขอหรือผู้มอบอำนาจ" span={3}>
                {requestMaster.applicant}
              </Descriptions.Item>
              <Descriptions.Item label="เลขบัตรประจำตัวประชาชน" span={2}>
                {requestMaster.personalId}
              </Descriptions.Item>
              <Descriptions.Item label="เบอร์โทร" span={1}>
                {requestMaster.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail ผู้ยื่นคำขอหรือผู้มอบอำนาจ" span={3}>
                {requestMaster.email}
              </Descriptions.Item>
              <Descriptions.Item label="หมายเหตุ" span={3}>
                {requestMaster.remark}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="สถานะการดำเนินงาน" />
            <Table dataSource={operations} pagination={false} bordered rowKey="operationNo">
              <Column title="ลำดับ" dataIndex="sequence" key="sequence" />
              <Column
                title="กระบวนการ (ขั้นตอน)"
                key="operationName"
                render={({ operationNo, operationName, operationOnsiteDate, operationRemark }) => {
                  if (operationNo === '02' && operationOnsiteDate !== null) {
                    return `${operationName} : ${dateFormat(operationOnsiteDate)}`
                  } else if (operationNo === '04') {
                    return `${operationName} : ${operationRemark}`
                  } else {
                    return operationName
                  }
                }}
              />
              <Column
                title="วันที่และเวลา"
                key="createdAt"
                render={({ createdAt }) => dateTimeFormat(createdAt)}
              />
              <Column title="ผู้ลงข้อมูล" dataIndex="creatorName" key="creatorName" />
            </Table>
          </>
        )}
      </MainContent>
    </CommonLayout>
  )
}

export default RequestDetail
