import api from 'api'
import MasterData from 'api/types/MasterData'
import { useState, useEffect } from 'react'

export function getActOptions(masterData: MasterData) {
  const options = masterData?.acts.map((act) => ({ label: act.name, value: act.no }))
  return options || []
}

export function getProcessOptions(masterData: MasterData, actNo: string) {
  const options = masterData?.processes
    .filter((process) => process.actNo === actNo)
    .map((process) => ({ label: process.name, value: process.no }))
  return options || []
}

export function getActName(masterData: MasterData | null, actNo?: string) {
  return masterData?.acts.find((act) => act.no === actNo)?.name
}

export function getProcessName(masterData: MasterData, processNo?: string) {
  return masterData?.processes.find((process) => process.no === processNo)?.name
}

export function getOperationName(masterData: MasterData, operationNo?: string) {
  return masterData?.operations.find((operation) => operation.no === operationNo)?.name
}

function useMasterData() {
  const [masterData, setMasterData] = useState<MasterData>(null)

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get('/request-masters/master-data')
      data && setMasterData(data.masterData)
    }
    fetchData()
  }, [])

  return masterData
}

export default useMasterData
