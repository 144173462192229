import React from 'react'
import { Form, Input, Button } from 'antd'
import { Store } from 'antd/lib/form/interface'

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 6 },
}
const tailLayout = {
  wrapperCol: { offset: 10, span: 6 },
}

type LoginFormProps = {
  onSubmit: (values: Store) => void
}

export default function LoginForm({ onSubmit }: LoginFormProps) {
  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onSubmit}>
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          เข้าสู่ระบบ
        </Button>
      </Form.Item>
    </Form>
  )
}
